<template>
  <div>
    <div class="py-10 container">
        <div class="col-md-12"><h2 class="text-center text-blue" style="font-size: 40px; margin: 30px 0px;">TOP BUS ROUTES</h2></div>
        <div class="row">
            <div class="col-md-4 my-3" v-for="route in topRoutes" :key="route.id">
                <div class="col-md-12 bg-white p-3 text-dark-50">
                    <p class="mb-0"><i class="fas fa-road mr-3" style="font-size: 18px;"></i>{{route[0].name}}</p>
                </div>
            </div>
        </div>
    </div>
    <div class="py-10 container" v-if="this.getCompanyInfo.id==1">
      <div class="col-md-12"><h2 class="text-center text-blue" style="font-size: 40px; margin: 0px 0px 30px;">AVAILABLE BUS OPERATORS</h2></div>
      <div class="row justify-content-center">
        <div class="col-md-3 my-3" v-for="operator in operators" :key="operator.id">
          <div class="col-md-12 bg-white p-3 text-muted text-center" style="min-height: 105px; display: flex; justify-content: space-around; align-items: center;">
            <div>
              <img v-lazy="operator.logo" :alt="operator.name" style="max-height: 50px; max-width: 200px;">
              <p class="mt-3 mb-0" style="font-size: 1.5rem; font-weight: 300;">{{operator.name}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

    import etcService from '@/core/services/models/etc.service'
    import {mapGetters} from "vuex";

    export default {
        name: 'TopRoutes',
        data(){
            return{
                topRoutes :[],
                operators :[],
            }
        },
        computed:{
          ...mapGetters(['getCompanyInfo']),
        },
        mounted(){
            etcService.getTopRoutes( this.getCompanyInfo.website_content.top_routes, s => {this.topRoutes = [...s], e => this.$toasted.error("Could not fetch top routes")})
            if(this.getCompanyInfo.id==1){
              etcService.getOperators(s=> { this.operators = [...s], e => this.$toasted.error("Could not fetch operators list")})
            }
        }
    }
</script>

<style scoped>

</style>
